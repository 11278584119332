import { BigEps, IsEqualEps, RadDeg } from '../engine/geometry/geometry.js';
import { AddDiv, ClearDomElement } from '../engine/viewer/domutils.js';
import { AddSvgIconElement, IsDarkTextNeededForColor } from './utils.js';

import * as THREE from 'three';
import { ColorComponentToFloat, RGBColor } from '../engine/model/color.js';

function GetFaceWorldNormal (intersection)
{
    let normalMatrix = new THREE.Matrix4 ();
    intersection.object.updateWorldMatrix (true, false);
    normalMatrix.extractRotation (intersection.object.matrixWorld);
    let faceNormal = intersection.face.normal.clone ();
    faceNormal.applyMatrix4 (normalMatrix);
    return faceNormal;
}

function CreateMaterial (color)
{
    return new THREE.LineBasicMaterial ({
        color : color,
        depthTest : false
    });
}

function CreateLineFromPoints (points, material)
{
    let geometry = new THREE.BufferGeometry ().setFromPoints (points);
    return new THREE.Line (geometry, material);
}

class Marker
{
    constructor (intersection, radius, color)
    {
        this.intersection = null;
        this.markerObject = new THREE.Object3D ();

        let geometry = new THREE.SphereGeometry( radius, 5, 5 );
        let material = CreateMaterial (color);
        let sphere = new THREE.Mesh( geometry, material );
        this.markerObject.add (sphere);

        this.UpdatePosition (intersection);
    }

    UpdatePosition (intersection)
    {
        this.intersection = intersection;
        this.markerObject.position.set (this.intersection.point.x, this.intersection.point.y, this.intersection.point.z);
    }

    Show (show)
    {
        this.markerObject.visible = show;
    }

    GetIntersection ()
    {
        return this.intersection;
    }

    GetObject ()
    {
        return this.markerObject;
    }
}
class BE_Marker
{
    constructor (intersection, radius, color=null)
    {
        this.intersection = null;
        this.markerObject = new THREE.Object3D ();

        let material = CreateMaterial (color);
        let circleCurve = new THREE.EllipseCurve (0.0, 0.0, radius, radius, 0.0, 2.0 * Math.PI, false, 0.0);
        this.markerObject.add (CreateLineFromPoints (circleCurve.getPoints (50), material));
        this.markerObject.add (CreateLineFromPoints ([new THREE.Vector3 (-radius, 0.0, 0.0), new THREE.Vector3 (radius, 0.0, 0.0)], material));
        this.markerObject.add (CreateLineFromPoints ([new THREE.Vector3 (0.0, -radius, 0.0), new THREE.Vector3 (0.0, radius, 0.0)], material));

        this.UpdatePosition (intersection);
    }

    UpdatePosition (intersection)
    {
        this.intersection = intersection;
        let faceNormal = GetFaceWorldNormal (this.intersection);
        this.markerObject.updateMatrixWorld (true);
        this.markerObject.position.set (0.0, 0.0, 0.0);
        this.markerObject.lookAt (faceNormal);
        this.markerObject.position.set (this.intersection.point.x, this.intersection.point.y, this.intersection.point.z);
    }

    Show (show)
    {
        this.markerObject.visible = show;
    }

    GetIntersection ()
    {
        return this.intersection;
    }

    GetObject ()
    {
        return this.markerObject;
    }
}
export class InjectTool
{
    constructor (viewer, settings)
    {
        this.viewer = viewer;
        this.settings = settings;
        this.isActive = false;
        this.markers = [];
        this.tempMarker = null;

        this.panel = null;
        this.button = null;
        this.sidebar = null;
        this.pins = {};

        this.mode = "";
        this.registeredPoints = [];
        this.injectionPoints = [];
    }

    SaveSettings()
    {
        grx.save();
    }
    SetMode(mode)
    {
        this.mode = mode;
    }
    SetNeedleLength(length)
    {
        grx.setNeedleLength(length);
    }
    SetRegistrationMode()
    {
        this.SetMode('AddRegisterPoint');
    }
    // BOZO: do R=>V test instead of V=>R test to avoid damaging patient
    /*
    SetRegistrationTestMode()
    {
        this.SetMode('TestRegisterPoint');
    }
    */
    ShowRegistrationTestPoint()
    {
        if (this.registrationTestMarker)
        {
            this.registrationTestMarker.Show(false);
            this.registrationTestMarker = null;
        }

        let real = grx.getPosition();
        let virtual = grx.R2VTransform(real);
        let intersection = this.templateIntersection;
        intersection.point.x = virtual.x;
        intersection.point.y = virtual.y;
        intersection.point.z = virtual.z;
        this.registrationTestMarker = this.GenerateBEMarker(intersection, "purple");
        this.registrationTestMarker.Show(true);
    }
    HideRegistrationTestPoint()
    {
        if (this.registrationTestMarker)
        {
            this.registrationTestMarker.Show(false);
            this.registrationTestMarker = null;
        }
    }
    SetInjectionMode(input, retractInput)
    {
        this.injectionAmountInput = input;
        this.retractAmountInput = retractInput;
        this.SetMode('AddInjectionPoint');
    }
    Register()
    {
        grx.CreateTransformations(this.registeredPoints);
    }

    SetEntryPoint()
    {
        this.entryPoint = grx.getPosition();
        let virtual = grx.R2VTransform(this.entryPoint);

        let intersection = this.templateIntersection;
        intersection.point.x = virtual.x;
        intersection.point.y = virtual.y;
        intersection.point.z = virtual.z;

        this.entryPointMarker = this.GenerateBEMarker(intersection, "green");
        this.entryPointMarker.Show(true);
    }
    ClearEntryPoint()
    {
        this.entryPoint = null;
        this.entryPointMarker.show(false);
        this.entryPointMarker = null;
    }

    DownloadPins(){
        this.DownloadObjectAsJson(this.pins, "injection_sites");
    }
    DownloadObjectAsJson(exportObj, exportName){
        var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj));
        var downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href", dataStr);
        downloadAnchorNode.setAttribute("download", exportName + ".json");
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    }

    SetButton (button)
    {
        this.button = button;
    }

    IsActive ()
    {
        return this.isActive;
    }

    SetActive (isActive)
    {
        if (this.isActive === isActive) {
            return;
        }
        this.isActive = isActive;
        this.button.SetSelected (isActive);
        if (this.isActive) {
            this.panel = AddDiv (document.body, 'ov_measure_panel');
            this.UpdatePanel ();
            this.Resize ();
        } else {
            this.ClearMarkers ();
            this.panel.remove ();
        }
        if (!this.toolType) {
            this.sidebar.SetDefaultPinType();
        }
    }

    Click (mouseCoordinates)
    {
        let intersection = this.viewer.GetMeshIntersectionUnderMouse (mouseCoordinates);
        if (intersection === null) {
            return;
        }

        switch(this.mode)
        {
            case "AddRegisterPoint":
                this.AddRegisterMarker (intersection);
                break;
            case "TestRegisterPoint":
                grx.testRegistrationPoint(intersection);
                break;
            case "AddInjectionPoint":
                this.AddInjectionMarker (intersection);
                break;
            default:
                this.AddMarker (intersection, this.pinColor);
                break;
        }
    }

    UpdateSidebar(){
//        this.sidebar.SetPinValue(this.pinType, this.pins[this.pinType].length);
        this.sidebar.SetRegisteredPoints(this.registeredPoints);
    }

    MouseMove (mouseCoordinates)
    {
        let intersection = this.viewer.GetMeshIntersectionUnderMouse (mouseCoordinates);
        if (intersection === null) {
            if (this.tempMarker !== null) {
                this.tempMarker.Show (false);
                this.tempMarker = null;
                this.viewer.Render ();
            }
            return;
        }

        // bit of a kludge
        if (!this.templateIntersection) this.templateIntersection = intersection;

        if (this.tempMarker === null) {
            switch (this.mode)
            {
                case "AddRegisterPoint":
                case "TestRegisterPoint":
                    this.tempMarker = this.GenerateBEMarker (intersection);
                    break;
                case "AddInjectionPoint":
                    this.tempMarker = this.GenerateBEMarker (intersection, "red");
                    break;
                default:
                    this.tempMarker = this.GenerateMarker (intersection, "black");
                    break;
            }
        }
        this.tempMarker.UpdatePosition (intersection);
        this.tempMarker.Show (true);
        this.viewer.Render ();
    }

    AddMarker (intersection, color)
    {
        let marker = this.GenerateMarker (intersection, color);

        if (!this.pins[this.pinType]) {
            this.pins[this.pinType] = [];
        }
        this.pins[this.pinType].push(intersection.point);

        this.UpdateSidebar ();
    }
    AddInjectionMarker (intersection, color="red")
    {
        if (!this.entryPoint)
        {
            alert("Error: Entry point needs to be set before injections can be added");
            return;
        }
        let real = grx.V2RTransform(intersection.point);
        if (!grx.checkInjectionDistance(this.entryPoint, real))
        {
            alert("Error: intended injection point exceeds reach of needle");
            return;
        }

        let marker = this.GenerateMarker (intersection, color);
        marker.Show(true);
        let injection = {
            virtual: intersection.point,
            real: real,
            amount: this.injectionAmountInput.value
        }
        this.injectionPoints.push(injection);
    }
    async PerformInjections()
    {
        let tool = this;
        // can't use Array.forEach with async
        for (const injection of this.injectionPoints)
        {
            await grx.pivotInject(this.entryPoint, injection.real, injection.amount, this.retractAmountInput.value);
        }
        await grx.retract();
    }

    AddRegisterMarker (intersection, color=null)
    {
        let marker = this.GenerateBEMarker (intersection, color);
        let tool = this;

        // this.registeredPoints.push(marker);
        let id = "modal_register_1";
        grx.activateJoystick();
        let modal = this.ShowModal(id, "Register Location Point",
                        [
                            "Use the Joystick to move the needle to the corresponding point on the mouse.",
                            "Once position is correct, click the \"add\" button to add registration point.",
                            "Click \"cancel\" to abort"
                        ],
                        {
                            title: "add",
                            CB: (ev) => {
                                marker.Show(true);
                                let virtual = {
                                    x: intersection.point.x,
                                    y: intersection.point.y,
                                    z: intersection.point.z
                                };
                                let point = {
                                    virtual: virtual,
                                    real: grx.getPosition()
                                };
                                tool.registeredPoints.push(point);

                                // close and remove modal
                                MicroModal.close(id);
                                let modal = document.querySelector(`#${id}`);
                                modal.parentElement.removeChild(modal);

                                tool.UpdateSidebar ();
                                grx.deactivateJoystick();
                            }
                        },
                        {
                            title: "cancel",
                            CB: (ev) => {
                                marker.Show(false);

                                // close and remove modal
                                MicroModal.close(id);
                                let modal = document.querySelector(`#${id}`);
                                modal.parentElement.removeChild(modal);

                                tool.UpdateSidebar ();
                                grx.deactivateJoystick();
                            }
                        }
                    );

    }
    ShowModal(id, title, texts, button1, button2)
    {
        let html_text = `<div class="micromodal-slide modal" id="${id}" aria-hidden="true">
                            <div class="modal__overlay" tabindex="-1" data-micromodal-close="">
                                <div class="modal__container" role="dialog" aria-modal="true" aria-labelledby="modal-1-title">
                                    <header class="modal__header">
                                        <h2 class="modal__title" id="modal-1-title"> ${title} </h2>
                                        <button class="modal__close" aria-label="Close modal" data-micromodal-close=""></button>
                                    </header>
                                    <div class="modal__content" id="modal-1-content">`;
        texts.forEach((value,index)=>{ html_text += `<p> ${value} </p>`});
        html_text += `              </div>
                                    <footer class="modal__footer">
                                    </footer>
                                </div>
                            </div>
                        </div>`;
        let div = document.createElement("div");
        div.innerHTML = html_text;
        let footer = div.querySelector("footer");
        if (button1)
        {
            let button = document.createElement("button");
            button.classList.add("modal__btn");
            button.classList.add("modal__btn-primary");
            button.innerText = button1.title;
            button.onclick = button1.CB;
            footer.appendChild(button);
        }
        if (button2)
        {
            let button = document.createElement("button");
            button.classList.add("modal__btn");
            button.innerText = button2.title;
            button.onclick = button2.CB;
            footer.appendChild(button);
        }

        document.body.appendChild(div);
        MicroModal.show(id);
        return div;
    }

    GenerateMarker (intersection, color)
    {
        let boundingSphere = this.viewer.GetBoundingSphere ((meshUserData) => {
            return true;
        });

        let radius = boundingSphere.radius / 200.0;
        if (this.mode == "AddInjectionPoint")
        {
            radius = radius / 5 * this.injectionAmountInput.value /0.4;
        }

        let marker = new Marker (intersection, radius, color);
        this.viewer.AddExtraObject (marker.GetObject ());
        return marker;
    }
    GenerateBEMarker (intersection, color=null)
    {
        let boundingSphere = this.viewer.GetBoundingSphere ((meshUserData) => {
            return true;
        });

        let radius = boundingSphere.radius / 20.0;
        let marker = new BE_Marker (intersection, radius, color);
        this.viewer.AddExtraObject (marker.GetObject ());
        return marker;
    }

    UpdatePanel ()
    {
        function BlendBackgroundWithPageBackground (backgroundColor)
        {
            let bodyStyle = window.getComputedStyle (document.body, null);
            let bgColors = bodyStyle.backgroundColor.match (/\d+/g);
            if (bgColors.length < 3) {
                return new RGBColor (backgroundColor.r, backgroundColor.g, backgroundColor.b);
            }
            let alpha = ColorComponentToFloat (backgroundColor.a);
            return new RGBColor (
                parseInt (bgColors[0], 10) * (1.0 - alpha) + backgroundColor.r * alpha,
                parseInt (bgColors[1], 10) * (1.0 - alpha) + backgroundColor.g * alpha,
                parseInt (bgColors[2], 10) * (1.0 - alpha) + backgroundColor.b * alpha
            );
        }

        function AddValue (panel, icon, title, value)
        {
            let svgIcon = AddSvgIconElement (panel, icon, 'left_inline');
            svgIcon.title = title;
            AddDiv (panel, 'ov_measure_value', value);
        }

        ClearDomElement (this.panel);
        if (this.settings.backgroundIsEnvMap) {
            this.panel.style.color = '#ffffff';
            this.panel.style.backgroundColor = 'rgba(0,0,0,0.5)';
        } else {
            let blendedColor = BlendBackgroundWithPageBackground (this.settings.backgroundColor);
            if (IsDarkTextNeededForColor (blendedColor)) {
                this.panel.style.color = '#000000';
            } else {
                this.panel.style.color = '#ffffff';
            }
            this.panel.style.backgroundColor = 'transparent';
        }
        if (this.markers.length === 0) {
            this.panel.innerHTML = 'Select a point.';
        } else if (this.markers.length === 1) {
            this.panel.innerHTML = 'Select another point.';
        } else {
            let calcResult = CalculateMarkerValues (this.markers[0], this.markers[1]);

            if (calcResult.pointsDistance !== null) {
                AddValue (this.panel, 'measure_distance', 'Distance of points', calcResult.pointsDistance.toFixed (3));
            }
            if (calcResult.parallelFacesDistance !== null) {
                AddValue (this.panel, 'measure_distance_parallel', 'Distance of parallel faces', calcResult.parallelFacesDistance.toFixed (3));
            }
            if (calcResult.facesAngle !== null) {
                let degreeValue = calcResult.facesAngle * RadDeg;
                AddValue (this.panel, 'measure_angle', 'Angle of faces', degreeValue.toFixed (1) + '\xB0');
            }
        }
        this.Resize ();
    }

    Resize ()
    {
        if (!this.isActive) {
            return;
        }
        let canvas = this.viewer.GetCanvas ();
        let canvasRect = canvas.getBoundingClientRect ();
        let panelRect = this.panel.getBoundingClientRect ();
        let canvasWidth = canvasRect.right - canvasRect.left;
        let panelWidth = panelRect.right - panelRect.left;
        this.panel.style.left = (canvasRect.left + (canvasWidth - panelWidth) / 2) + 'px';
        this.panel.style.top = (canvasRect.top + 10) + 'px';
    }

    ClearMarkers ()
    {
        this.viewer.ClearExtra ();
        this.markers = [];
        this.tempMarker = null;
    }
    ClearRegistrationPoints ()
    {
        this.viewer.ClearExtra ();
        this.registeredPoints = [];
        this.tempMarker = null;
        this.UpdateSidebar ();
    }
    ClearInjectionPoints ()
    {
        this.viewer.ClearExtra ();
        this.injectionPoints = [];
        this.tempMarker = null;
    }
}
